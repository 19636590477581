import React, { useState, useEffect } from "react";
import { responsive } from "../../data";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ImPlay } from "react-icons/im";
import { Link } from "react-router-dom";

export default function Video() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=1&subcategoryid=0`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const groupedData = items.reduce((acc, item) => {
    if (!acc[item.SubCategoryd]) {
      acc[item.SubCategoryd] = [];
    }
    acc[item.SubCategoryd].push(item);
    return acc;
  }, {});

  return (
    <>
      <div className="container-lg px-2 px-lg-0">
        <h5 className="my-2">
          <Link to="/video">ویدیو ها</Link>
        </h5>
        {Object.keys(groupedData).map((subCategory) => (
          <div
            key={subCategory}
            className="position-relative small--thumbnails ms-2"
          >
            <Link to={`/video/${subCategory}`}>
              <h6 className="my-2">{subCategory}</h6>
            </Link>
            <Carousel rtl responsive={responsive}>
              {groupedData[subCategory].map((video) => (
                <div
                  key={video.id}
                  style={{
                    position: "relative",
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                >
                  <Link to={`/video/${video.SubCategory}/${video.id}`}>
                    <ImPlay
                      style={{
                        position: "absolute",
                        top: "38%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "red",
                        width: "3rem",
                        height: "3rem",
                      }}
                    />
                    <div className="overlay"></div>
                    <img
                      className="w-100"
                      src={video.ImageUrl}
                      alt="video thumbnails"
                    />
                    <p
                      className="text-truncate m-0"
                      style={{ maxWidth: "240px" }}
                    >
                      {video.Title}
                    </p>
                  </Link>
                </div>
              ))}
            </Carousel>

            <hr />
          </div>
        ))}
      </div>
    </>
  );
}
