import React from "react";
import Audio from "./Audio";
import Publication from "./Publication";
import Video from "./Video";

export default function Home() {
  return (
    <>
      <div className="container-lg px-0">
        <Video />
        <hr />
        <Audio />
        <hr />
        <Publication />
        <hr />
      </div>
    </>
  );
}
