import React from "react";

export default function NotFound() {
  return (
    <div className="text-center">
      <p style={{ fontSize: "10rem" }}>404</p>
      <p>Page not found</p>
    </div>
  );
}
