// RegularLayout.jsx
import React from "react";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";

const RegularLayout = ({ children }) => (
  <div>
    <Header />
    <ScrollToTop />
    <main>{children}</main>
    <Footer />
  </div>
);

export default RegularLayout;
