import React, { useState, useEffect } from "react";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";

export default function AudioPreview() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory, id } = useParams();

  let subcategoryId;

  if (subcategory === "positionofht") {
    subcategoryId = 5;
  } else if (subcategory === "towardslight") {
    subcategoryId = 6;
  } else if (subcategory === "tafsir") {
    subcategoryId = 7;
  } else if (subcategory === "qandawithamir") {
    subcategoryId = 8;
  } else if (subcategory === "sirat") {
    subcategoryId = 9;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=2&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const filterAudio = items.filter((audio) => audio.id === id);

  const audioPreview = filterAudio.map((audio) => {
    return (
      <div
        id={audio.id}
        className="audio-preview bg-secondary text-white text-center py-4 px-2 d-flex flex-column justify-content-between"
        style={{ minWidth: "300px", maxHeight: "320px" }}
      >
        <h6>{audio.Title}</h6>
        <small>{audio.SubCategoryd}</small>
        <audio controls className="w-100">
          <source src={audio.FileUrl} />
          ستاسو په کمپیوتر کی غږیزی کار نکوی
        </audio>
      </div>
    );
  });

  const audioListFilter = items.filter((audio) => audio.id !== id);

  const audios = audioListFilter.map((item) => {
    return (
      <tr key={item.id}>
        <td
          style={{ height: "70px" }}
          className="row align-items-center p-0 w-100 m-0"
        >
          <a
            href={item.FileUrl}
            target={"_blank"}
            rel={"noreferrer"}
            className="col-2 p-0 m-auto d-flex justify-content-center"
          >
            <FiDownload style={{ height: "25px", width: "25px" }} />
          </a>
          <Link
            to={`/audio/${item.SubCategory}/${item.id}`}
            className="m-auto text-truncate col-6 p-0"
            style={{ maxWidth: "290px" }}
          >
            {item.Title}
          </Link>
          <div
            className="col-1 text-start p-0 m-auto"
            style={{ width: "40px" }}
          >
            <img
              src={item.ImageUrl}
              style={{
                height: "40px",
                borderRadius: "10px",
                width: "40px",
              }}
              alt="audio thumbnail"
            />
          </div>
          <Link
            to={`/audio/${item.SubCategory}/${item.id}`}
            className="col-2 p-0 m-auto d-flex justify-content-center"
          >
            <BsFillPlayCircleFill style={{ height: "40px", width: "40px" }} />
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="container">
        <div className="d-flex flex-column flex-md-row">
          {audioPreview}
          <div
            className="w-100 bg-secondary bg-opacity-25 overflow-auto position-relative"
            style={{ height: "300px" }}
          >
            <table className="table table-striped">
              <tbody>{audios}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
