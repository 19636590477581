import React, { useState, useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsChatRightDotsFill } from "react-icons/bs";
import { ImPlay } from "react-icons/im";
import { MdUpdate } from "react-icons/md";
import { Link, useParams } from "react-router-dom";

export default function VideoView() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory, id } = useParams();

  let subcategoryId;

  if (subcategory === "ummatcapacity") {
    subcategoryId = 1;
  } else if (subcategory === "didyouknow") {
    subcategoryId = 2;
  } else if (subcategory === "campaigns") {
    subcategoryId = 3;
  } else if (subcategory === "various") {
    subcategoryId = 4;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=1&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const filterVideo = items.filter((video) => video.id === id);

  const video = filterVideo.map((video) => {
    return (
      <div
        key={video.id}
        style={{ height: "300px" }}
        className="col-md-6 ps-md-2"
      >
        <iframe
          className="w-100 h-100"
          src={video.YoutubeUrl}
          title={video.Title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <h4>{video.Title}</h4>
        <hr />
      </div>
    );
  });

  const playlistFilter = items.filter((link) => link.id !== id);

  /* mapping over youtube links array located in data.js and producing each playlist element */
  const playlistVideo = playlistFilter.map((link) => {
    return (
      <Link
        to={`/video/${link.SubCategory}/${link.id}`}
        key={link.id}
        className="d-flex my-3 pb-2 justify-content-between align-items-center border-bottom border-secondary text-decoration-none text-dark"
        style={{ height: "100px" }}
      >
        {/* video thumbnails column */}
        <div className="col-3">
          <div className="position-relative small--thumbnails">
            <ImPlay
              style={{
                height: "40%",
                width: "40%",
                color: "red",
                top: "25%",
                right: "30%",
              }}
              className="position-absolute "
            />
            <div className="overlay"></div>
            <img className="w-100" src={link.ImageUrl} alt="video thumbnails" />
          </div>
        </div>
        {/* video details column */}
        <div className="col-9 h-100 p-3 d-flex flex-column justify-content-between">
          <p style={{ fontSize: ".9rem", marginBottom: "0" }}>{link.Title}</p>
          <div className="d-flex align-items-center justify-content-evenly">
            {/* views */}
            <div className="d-flex align-items-center">
              <AiFillEye
                style={{
                  width: "25px",
                  height: "25px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                23.4k
              </p>
            </div>
            {/* comments */}
            <div className="d-flex align-items-center">
              <BsChatRightDotsFill
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                11.2k
              </p>
            </div>
            {/* date posted */}
            <div className="d-flex align-items-center">
              <MdUpdate
                style={{
                  width: "25px",
                  height: "25px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                14 Sep 2022
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  });

  return (
    <>
      <div className="container-lg px-0">
        <h5 className="px-2 px-lg-0 my-2">
          <Link to="/video">ویدیو ها</Link>
        </h5>
        {/* Two columns of video - first: embeded video, second: playlist */}
        <div className="d-md-flex w-100 px-2 px-lg-0">
          {video}
          <div
            style={{ height: "850px" }}
            className="col-md-6 p-2 bg-secondary bg-opacity-25"
          >
            <h6>ویدیو های مرتبط</h6>
            <div style={{ height: "800px" }} className="overflow-auto">
              {playlistVideo}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
