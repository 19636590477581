import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DropdownBtn from "./DropdownBtn";

export default function NewsSubcat() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory } = useParams();

  let subcategoryId;

  if (subcategory === "articles") {
    subcategoryId = 16;
  } else if (subcategory === "infographic") {
    subcategoryId = 17;
  } else if (subcategory === "videos") {
    subcategoryId = 18;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=4&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }
  return (
    <>
      <div>
        <div className="container-lg px-0">
          <div className="d-flex">
            <h5 className="my-2 ms-auto">امت نیوز</h5>
            <DropdownBtn />
          </div>
          <table className="table table-striped">
            <tbody>
              {items.map((news) => {
                return (
                  <tr key={news.id}>
                    <td>
                      <Link
                        className="d-flex flex-column-reverse flex-md-row p-3 text-decoration-none text-dark"
                        to={`/news/${news.SubCategory}/${news.id}`}
                      >
                        <img
                          src={news.ImageUrl}
                          alt="news thumbnail"
                          style={{ width: "250px", height: "170px" }}
                        />
                        <div className="me-md-3">
                          <h5>{news.Title}</h5>
                          <small dir="ltr">{news.PostDate}</small>
                          <p>{news.ShortDesc}</p>
                        </div>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mx-auto text-center">
            <small className="p-2 bg-secondary text-light rounded">
              پایان مطالب
            </small>
          </div>
        </div>
      </div>
    </>
  );
}
