import { Route, Switch } from "react-router-dom";

import "./App.css";
import NotFound from "./components/NotFound";
import Audio from "./pages/audio/Audio";
import AudioPreview from "./pages/audio/AudioPreview";
import AudioSubcat from "./pages/audio/AudioSubcat";
import Book from "./pages/book/Book";
import BookSubcat from "./pages/book/BookSubcat";
import Booklet from "./pages/booklet/Booklet";
import BookletSubcat from "./pages/booklet/BookletSubcat";
import Home from "./pages/landingPage/Home";
import Magazine from "./pages/magazine/Magazine";
import MagazineSubcat from "./pages/magazine/MagazineSubcat";
import Minibook from "./pages/minibook/Minibook";
import MinibookSubcat from "./pages/minibook/MinibookSubcat";
import News from "./pages/news/News";
import NewsSubcat from "./pages/news/NewsSubcat";
import NewsView from "./pages/news/NewsView";
import Video from "./pages/video/Video";
import VideoSubcat from "./pages/video/VideoSubcat";
import VideoView from "./pages/video/VideoView";
import Login from "./admin/Login";
import RegularLayout from "./RegularLayout";
import AdminLayout from "./AdminLayout";
import Dashboard from "./admin/Dashboard";
import Tables from "./admin/Tables";
import Profile from "./admin/Profile";
import Analytics from "./admin/Analytics";
import Upload from "./admin/Upload";

const regularRoutes = [
  { path: "/", component: Home },
  { path: "/news", component: News },
  { path: "/news/:subcategory", component: NewsSubcat },
  { path: "/news/:subcategory/:id", component: NewsView },
  { path: "/video", component: Video },
  { path: "/video/:subcategory", component: VideoSubcat },
  { path: "/video/:subcategory/:id", component: VideoView },
  { path: "/audio", component: Audio },
  { path: "/audio/:subcategory", component: AudioSubcat },
  { path: "/audio/:subcategory/:id", component: AudioPreview },
  { path: "/book", component: Book },
  { path: "/book/:subcategory", component: BookSubcat },
  { path: "/booklet", component: Booklet },
  { path: "/booklet/:subcategory", component: BookletSubcat },
  { path: "/minibook", component: Minibook },
  { path: "/minibook/:subcategory", component: MinibookSubcat },
  { path: "/magazine", component: Magazine },
  { path: "/magazine/:subcategory", component: MagazineSubcat },
];

const adminRoutes = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/tables", component: Tables },
  { path: "/admin/profile", component: Profile },
  { path: "/admin/analytics", component: Analytics },
  { path: "/admin/upload", component: Upload },
];

export default function App() {
  return (
    <div>
      <Switch>
        {regularRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact
            render={(props) => (
              <RegularLayout>
                <route.component {...props} />
              </RegularLayout>
            )}
          />
        ))}
        {adminRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact
            render={(props) => (
              <AdminLayout>
                <route.component {...props} />
              </AdminLayout>
            )}
          />
        ))}
        <Route exact path="/login" render={Login} />
        {/* not found route */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

// {/* home route */}
// <Route exact path="/">
//   <Home />
// </Route>

// {/* news routes */}
// <Route exact path="/news">
//   <News />
// </Route>
// <Route exact path="/news/:subcategory" children={<NewsSubcat />} />
// <Route path="/news/:subcategory/:id" children={<NewsView />} />

// {/* video routes */}
// <Route exact path="/video">
//   <Video />
// </Route>
// <Route exact path="/video/:subcategory" children={<VideoSubcat />} />
// <Route path="/video/:subcategory/:id" children={<VideoView />} />

// {/* audio routes */}
// <Route exact path="/audio">
//   <Audio />
// </Route>
// <Route exact path="/audio/:subcategory" children={<AudioSubcat />} />
// <Route path="/audio/:subcategory/:id" children={<AudioPreview />} />

// {/* book routes */}
// <Route exact path="/book">
//   <Book />
// </Route>
// <Route exact path="/book/:subcategory" children={<BookSubcat />} />

// {/* booklet routes */}
// <Route exact path="/booklet">
//   <Booklet />
// </Route>
// <Route
//   exact
//   path="/booklet/:subcategory"
//   children={<BookletSubcat />}
// />

// {/* minibook routes */}
// <Route exact path="/minibook">
//   <Minibook />
// </Route>
// <Route
//   exact
//   path="/minibook/:subcategory"
//   children={<MinibookSubcat />}
// />

// {/* magazine routes */}
// <Route exact path="/magazine">
//   <Magazine />
// </Route>
// <Route
//   exact
//   path="/magazine/:subcategory"
//   children={<MagazineSubcat />}
// />

// {/* not found route */}
// <Route>
//   <NotFound />
// </Route>
