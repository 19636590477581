import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export default function NewsView() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory, id } = useParams();

  let subcategoryId;

  if (subcategory === "articles") {
    subcategoryId = 16;
  } else if (subcategory === "infographic") {
    subcategoryId = 17;
  } else if (subcategory === "videos") {
    subcategoryId = 18;
  }

  function toHtml(data) {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=4&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const filteredItems = items.filter((item) => item.id === id);

  return (
    <>
      <div className="container-lg px-0">
        <h5 className="my-3">امت نیوز</h5>
        {filteredItems.map((news) => {
          return (
            <div className="p-4 bg-secondary bg-opacity-25" key={news.id}>
              <div>
                <h5>{news.Title}</h5>
                <small>{news.PostDate}</small>
                {toHtml(news.fulldesc)}
                <div className="text-center">
                  <img
                    src={news.ImageUrl}
                    alt="news thumnail"
                    style={{ height: "400px", width: "500px" }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
