import React from "react";
import { Link } from "react-router-dom";
import { catLinks } from "../data.js";
import { AiFillHome } from "react-icons/ai";
import TopRibbon from "./TopRibbon.jsx";
import Search from "./Search.jsx";

export default function Header() {
  const catLinksMenu = catLinks.map((link) => {
    return (
      <li key={link.id} className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle text-white"
          href={"#"}
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {link.name}
        </a>
        <ul
          className="dropdown-menu dropdown-menu-end text-end"
          style={{ zIndex: "2000" }}
        >
          {link.subcatLinks.map((sublink) => {
            return (
              <li key={sublink.id}>
                <Link to={sublink.url} className="dropdown-item">
                  {sublink.text}
                </Link>
              </li>
            );
          })}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <Link className="dropdown-item" to={link.url}>
              {link.text}
            </Link>
          </li>
        </ul>
      </li>
    );
  });

  return (
    <div className="container-lg h-100 px-0">
      {/* Top ribbon and Banner image*/}
      <TopRibbon />

      {/* Nav links and search */}
      <div className="navbar-color w-100 px-lg-0 d-flex justify-content-between">
        <nav className="text-white navbar navbar-expand-lg">
          <button
            className="navbar-toggler me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mt-2 mt-lg-0 pe-0">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <AiFillHome
                    style={{
                      color: "white",
                      width: "1.7rem",
                      height: "1.7rem",
                    }}
                  />
                </Link>
              </li>
              {/* mapping for links to show on navbar */}
              {catLinksMenu}
            </ul>
          </div>
        </nav>
        <Search />
      </div>
    </div>
  );
}
