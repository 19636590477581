import React from "react";
import { FiSearch } from "react-icons/fi";

export default function Search() {
  return (
    <div>
      <form
        style={{ height: "60px" }}
        className="d-flex flex-row-reverse p-2"
        role="search"
      >
        <input
          className="form-control py-0 fs-6"
          type="search"
          placeholder="جستجو..."
          aria-label="Search"
        />
        <button
          className="btn btn-outline-secondary py-0 ms-2 text-white border-secondary"
          type="submit"
        >
          <FiSearch />
        </button>
      </form>
    </div>
  );
}
