import React from "react";
import PublicationCarousel from "./PublicationCarousel";

export default function Publication() {
  return (
    <div className="px-2 px-lg-0">
      <h5 className="my-2">نشرات</h5>
      <div className="w-100 bg-secondary bg-opacity-25">
        <PublicationCarousel />
      </div>
    </div>
  );
}
