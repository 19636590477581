// AdminLayout.jsx
import React from "react";
import Sidebar from "./admin/Sidebar";

const AdminLayout = ({ children }) => (
  <div dir="ltr" className="d-flex">
    <Sidebar />
    <main className="p-4">{children}</main>
  </div>
);

export default AdminLayout;
