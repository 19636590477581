import React from "react";

export default function TopRibbon() {
  return (
    <div>
      {/* Top ribbon */}
      <div
        className="w-100 px-2 py-1 text-white"
        style={{ backgroundColor: "#0583A2" }}
      >
        <p className="m-0" style={{ fontSize: "0.8rem" }}>
          Sep 8, 2022
        </p>
      </div>

      {/* Banner image */}
      <img className="w-100" src="./img/website banner.png" alt="Banner" />
    </div>
  );
}
