import React, { useState, useEffect } from "react";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
// import { audio } from "../../data";

export default function Audio() {
  // const audios = audio.map((item) => {
  //   return (
  //     <div key={item.id}>
  //       <Link to={item.url} className="text-decoration-none text-dark">
  //         <h6 className="pb-2">{item.title}</h6>
  //       </Link>
  //       <audio controls className="w-100">
  //         <source src="http://hizb-afghanistan.com/files/audios/Rasanai_01.mp3" />
  //         ستاسو په کمپیوتر کی غږیزی کار نکوی
  //       </audio>
  //     </div>
  //   );
  // });

  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=2&subcategoryid=0`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const audios = items.map((item) => {
    return (
      <tr key={item.id}>
        <td
          style={{ height: "70px" }}
          className="row align-items-center p-0 w-100 m-0"
        >
          <a
            href={item.FileUrl}
            target={"_blank"}
            rel={"noreferrer"}
            className="col-2 p-0 m-auto d-flex justify-content-center"
          >
            <FiDownload style={{ height: "25px", width: "25px" }} />
          </a>
          <Link
            to={`/audio/${item.SubCategory}/${item.id}`}
            className="m-auto text-truncate col-6 p-0"
            style={{ maxWidth: "290px" }}
          >
            {item.Title}
          </Link>
          <Link
            to={`/audio/${item.SubCategory}`}
            className="col-2 text-center p-0 m-auto d-none d-md-block"
          >
            {item.SubCategoryd}
          </Link>
          <div
            className="col-1 text-start p-0 m-auto"
            style={{ width: "40px" }}
          >
            <img
              src={item.ImageUrl}
              style={{
                height: "40px",
                borderRadius: "10px",
                width: "40px",
              }}
              alt="audio thumbnail"
            />
          </div>
          <Link
            to={`/audio/${item.SubCategory}/${item.id}`}
            className="col-2 p-0 m-auto d-flex justify-content-center"
          >
            <BsFillPlayCircleFill style={{ height: "40px", width: "40px" }} />
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="px-2 px-lg-0 container">
        <h5 className="my-2">
          <Link to="/audio">تمام صوتی ها</Link>
        </h5>
        <div
          style={{ height: "300px" }}
          className="w-100 bg-secondary bg-opacity-25 overflow-auto position-relative"
        >
          <table className="table table-striped">
            <tbody>{audios}</tbody>
          </table>
        </div>

        <h5 className="mt-4">بخش ها</h5>
        <div className="d-flex flex-wrap justify-content-evenly">
          <Link
            to="/audio/positionofht"
            className="bg-secondary my-3 py-5 px-1 text-white"
            style={{ height: "150px", width: "150px", textAlign: "center" }}
          >
            موقف حزب التحریر
          </Link>
          <Link
            to="/audio/towardslight"
            className="bg-secondary my-3 py-5 px-1 text-white"
            style={{ height: "150px", width: "150px", textAlign: "center" }}
          >
            بسوی روشنایی
          </Link>
          <Link
            to="/audio/tafsir"
            className="bg-secondary my-3 py-5 px-1 text-white"
            style={{ height: "150px", width: "150px", textAlign: "center" }}
          >
            سلسله درسهای تفسیر
          </Link>
          <Link
            to="/audio/qandawithamir"
            className="bg-secondary my-3 py-5 px-1 text-white"
            style={{ height: "150px", width: "150px", textAlign: "center" }}
          >
            پرسش و پاسخ با امیر حزب التحریر
          </Link>
          <Link
            to="/audio/sirat"
            className="bg-secondary my-3 py-5 px-1 text-white"
            style={{ height: "150px", width: "150px", textAlign: "center" }}
          >
            بخش سیرت
          </Link>
        </div>
      </div>
    </>
  );
}
