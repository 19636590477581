import React from "react";
import ContentForm from "./ContentForm";

export default function Upload() {
  return (
    <div>
      <ContentForm />
    </div>
  );
}
