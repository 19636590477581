import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { responsive, latestPublication } from "../../data";

export default function PublicationCarousel() {

  // TODO: Add API code blocks
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=3&subcategoryid=0`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <p className="text-center" dir="ltr">Loading...</p>;
  }

  if (error) {
    return <p className="text-center" dir="ltr">Something went wrong: {error.message}</p>;
  }


  const latestpublication = items.map((publication) => {
    return (
      <div
        key={publication.id}
        style={{
          position: "relative",
          marginLeft: "0.2rem",
          marginRight: "0.2rem",
        }}
      >
        <Link to={`/${publication.category}/${publication.subcat}`}>
          <div className="overlay" />
          <img
            className="w-100"
            src={publication.ImageUrl}
            alt="publication thumbnails"
          />
        </Link>
      </div>
    );
  });

  return (
    <div>
      <Carousel
        rtl
        responsive={responsive}
        autoPlay={true}
        ssr={true}
        rewind={true}
        rewindWithAnimation={true}
      >
        {latestpublication}
      </Carousel>
    </div>
  );
}
