import React from "react";
import { Link } from "react-router-dom";

export default function DropdownBtn() {
  return (
    <div>
      <div>
        <div className="btn-group">
          <button
            className="btn btn-secondary btn-sm dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            فلتر
          </button>
          <ul className="dropdown-menu dropdown-menu-end text-end">
            <li>
              <Link className="dropdown-item" to="/news">
                تمام اخبار
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/news/articles">
                مقالات
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/news/infographic">
                انفوگرافیک
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/news/videos">
                ویدیو ها
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
