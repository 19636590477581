import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { AiFillEye } from "react-icons/ai";
// import { BsChatRightDotsFill } from "react-icons/bs";
// import { MdUpdate } from "react-icons/md";
import { ImPlay } from "react-icons/im";

export default function Video() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=1&subcategoryid=0`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return <p className="text-center" dir="ltr">Something went wrong: {error.message}</p>;
  }
  /* mapping over youtube links array located in data.js and producing each playlist element */
  const playlistVideo = items.map((link) => {
    return (
      // TODO: change the path correct route
      <Link
        to={`/video/${link.SubCategory}/${link.id}`}
        key={link.id}
        className="d-flex my-3 pb-2 justify-content-between align-items-center border-bottom border-secondary text-decoration-none text-dark"
        style={{ height: "100px" }}
      >
        {/* video thumbnails column */}
        <div className="col-3">
          <div className="position-relative small--thumbnails">
            <ImPlay
              style={{
                height: "40%",
                width: "40%",
                color: "red",
                top: "25%",
                right: "30%",
              }}
              className="position-absolute "
            />
            <div className="overlay"></div>
            <img className="w-100" src={link.ImageUrl} alt="video thumbnails" />
          </div>
        </div>
        {/* video details column */}
        <div className="col-9 h-100 p-3 d-flex flex-column justify-content-between">
          <p style={{ fontSize: ".9rem", marginBottom: "0" }}>{link.Title}</p>
          {/* <div className="d-flex align-items-center justify-content-evenly">
            
            <div className="d-flex align-items-center">
              <AiFillEye
                style={{
                  width: "25px",
                  height: "25px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                23.4k
              </p>
            </div>
            
            <div className="d-flex align-items-center">
              <BsChatRightDotsFill
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                11.2k
              </p>
            </div>
            
            <div className="d-flex align-items-center">
              <MdUpdate
                style={{
                  width: "25px",
                  height: "25px",
                  marginLeft: ".5rem",
                }}
              />
              <p
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                14 Sep 2022
              </p>
            </div>
          </div> */}
        </div>
      </Link>
    );
  });

  const lastArrayElement = items.slice(-1);
  const latestVideo = lastArrayElement.map((video) => (
    <Link
      key={video.id}
      className="text-body"
      to={`/video/${video.SubCategory}/${video.id}`}
      style={{ textDecoration: "none", position: "relative" }}
    >
      <ImPlay
        style={{
          height: "40%",
          width: "40%",
          color: "red",
          right: "30%",
          top: "-30px",
          position: "absolute",
          zIndex: "1000",
        }}
      />
      <img
        style={{ height: "80%", width: "100%", objectFit: "cover" }}
        src={video.ImageUrl}
        alt="video thumbnail"
      />
      <h5 className="my-1 text-truncate" style={{ maxWidth: "540px" }}>
        {video.Title}
      </h5>
    </Link>
  ));

  return (
    <div>
      <h5 className="px-2 px-lg-0 my-2">
        <Link to="/video">ویدیو ها</Link>
      </h5>
      {/* Two columns of video - first: embeded video, second playlist */}
      <div className="d-md-flex w-100 px-2 px-lg-0">
        {/* embeded video container */}
        <div style={{ height: "370px" }} className="col-md-6 ps-md-2">
          {/* 
            TODO: add the latest video to home page by cutting out the last element of the video array
            I should find the array method to slice the last element of the array
          */}
          {latestVideo}
        </div>
        {/* playlist container */}
        <div
          style={{ height: "370px" }}
          className="col-md-6 p-2 bg-secondary bg-opacity-25"
        >
          <h6>ویدیو های مرتبط</h6>
          <div style={{ height: "320px" }} className="overflow-auto">
            {playlistVideo}
          </div>
        </div>
      </div>
      {/* End of Video Component */}
    </div>
  );
}
