import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";

export default function BookSubcat() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory } = useParams();

  let subcategoryId;

  if (subcategory === "governance") {
    subcategoryId = 19;
  } else if (subcategory === "htprogram") {
    subcategoryId = 20;
  } else if (subcategory === "globalactivity") {
    subcategoryId = 21;
  } else if (subcategory === "interactingwithummat") {
    subcategoryId = 22;
  } else if (subcategory === "contemporarycrisis") {
    subcategoryId = 23;
  } else if (subcategory === "leaders") {
    subcategoryId = 24;
  } else if (subcategory === "various") {
    subcategoryId = 25;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=5&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const styles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    placeItems: "start center",
    position: "relative",
  };

  const subcatBook = items.map((book) => {
    return (
      <div
        key={book.id}
        className="card m-2"
        style={{ width: "15rem", height: "23rem" }}
      >
        <a href={book.FileUrl} target={"_blank"} rel={"noreferrer"}>
          <img
            src={book.ImageUrl}
            className="card-img-top"
            alt="thumbnail"
            style={{ height: "18.4rem" }}
          />
        </a>
        <div className="card-body p-1">
          <p className="card-text mb-1">{book.Title}</p>
          <div
            className="d-flex justify-content-evenly"
            style={{ position: "absolute", bottom: "0.2rem", width: "14.3rem" }}
          >
            <a
              href={book.FileUrl}
              rel={"noreferrer"}
              target={"_blank"}
              className="btn btn-secondary w-100 p-1"
            >
              دانلود <FiDownload />
            </a>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="container-lg px-2 px-lg-0">
        <h5 className="my-2">کتاب ها</h5>

        {/* subcat navigation links */}

        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link className="nav-link" to="/book">
              تمام بخش ها
            </Link>
          </li>
          <li className="nav-item">
            {subcategory === "governance" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/governance"
              >
                دولت داری
              </Link>
            ) : (
              <Link className="nav-link" to="/book/governance">
                دولت داری
              </Link>
            )}
          </li>

          <li className="nav-item">
            {subcategory === "htprogram" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/htprogram"
              >
                برنامه حزبی
              </Link>
            ) : (
              <Link className="nav-link" to="/book/htprogram">
                برنامه حزبی
              </Link>
            )}
          </li>

          <li className="nav-item">
            {subcategory === "globalactivity" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/globalactivity"
              >
                فعالیت های جهانی
              </Link>
            ) : (
              <Link className="nav-link" to="/book/globalactivity">
                فعالیت های جهانی
              </Link>
            )}
          </li>

          <li className="nav-item">
            {subcategory === "interactingwithummat" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/interactingwithummat"
              >
                تعامل با امت
              </Link>
            ) : (
              <Link className="nav-link" to="/book/interactingwithummat">
                تعامل با امت
              </Link>
            )}
          </li>
          <li className="nav-item">
            {subcategory === "contemporarycrisis" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/contemporarycrisis"
              >
                بحران های معاصر
              </Link>
            ) : (
              <Link className="nav-link" to="/book/contemporarycrisis">
                بحران های معاصر
              </Link>
            )}
          </li>
          <li className="nav-item">
            {subcategory === "leaders" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/leaders"
              >
                امرا حزب
              </Link>
            ) : (
              <Link className="nav-link" to="/book/leaders">
                امرا حزب
              </Link>
            )}
          </li>
          <li className="nav-item">
            {subcategory === "various" ? (
              <Link
                className="nav-link active"
                aria-current="page"
                to="/book/various"
              >
                متفرقه
              </Link>
            ) : (
              <Link className="nav-link" to="/book/various">
                متفرقه
              </Link>
            )}
          </li>
        </ul>
        <div style={styles} className="w-100 bg-secondary bg-opacity-25 p-2">
          {subcatBook}
        </div>
        <div className="mx-auto text-center mt-4">
          <small className="p-2 bg-secondary text-light rounded">
            پایان مطالب
          </small>
        </div>
      </div>
    </>
  );
}
