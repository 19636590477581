import React, { useState, useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Magazine() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=3&subcategoryid=0`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const styles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    placeItems: "start center",
    position: "relative",
  };

  const magazineCards = items.map((magazine) => {
    return (
      <div
        key={magazine.id}
        className="card m-2"
        style={{ width: "15rem", height: "23rem" }}
      >
        <a href={magazine.FileUrl} target={"_blank"} rel={"noreferrer"}>
          <img
            src={magazine.ImageUrl}
            className="card-img-top"
            alt="thumbnail"
            style={{ height: "18.4rem" }}
          />
        </a>
        <div className="card-body p-1">
          <p className="card-text mb-1">{magazine.Title}</p>
          <div
            className="d-flex justify-content-evenly"
            style={{ position: "absolute", bottom: "0.2rem", width: "14.3rem" }}
          >
            <a
              href={magazine.FileUrl}
              target={"_blank"}
              rel={"noreferrer"}
              className="btn btn-secondary w-100 p-1"
            >
              دانلود <FiDownload />
            </a>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="container-lg px-2 px-lg-0">
        <h5 className="my-2">مجلات</h5>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className="nav-link active"
              aria-current="page"
              to="/magazine"
            >
              تمام بخش ها
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/khilafahmagazine">
              مجله خلافت
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/qeyam">
              رساله قیام
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/taghir">
              رساله تغییر
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/khilafahgahnama">
              گاهنامه خلافت
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/ummat">
              مجله امت
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/magazine/bookletandbrochure">
              بوکلت و بروشور
            </Link>
          </li>
        </ul>
        <div style={styles} className="w-100 bg-secondary bg-opacity-25 p-2">
          {magazineCards}
        </div>
        <div className="mx-auto text-center mt-4">
          <small className="p-2 bg-secondary text-light rounded">
            پایان مطالب
          </small>
        </div>
      </div>
    </>
  );
}
