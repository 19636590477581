import React, { useState, useEffect } from "react";
import { ImPlay } from "react-icons/im";
import { Link, useParams } from "react-router-dom";

export default function VideoSubcat() {
  const [items, setItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  let { subcategory } = useParams();

  let subcategoryId;

  if (subcategory === "ummatcapacity") {
    subcategoryId = 1;
  } else if (subcategory === "didyouknow") {
    subcategoryId = 2;
  } else if (subcategory === "campaigns") {
    subcategoryId = 3;
  } else if (subcategory === "various") {
    subcategoryId = 4;
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    fetch(
      `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=2&categoryid=1&subcategoryid=${subcategoryId}`
    )
      .then((response) => response.json())
      .then((items) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [subcategoryId]);

  if (isLoading) {
    return (
      <p className="text-center" dir="ltr">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <p className="text-center" dir="ltr">
        Something went wrong: {error.message}
      </p>
    );
  }

  const styles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gridGap: "1.3rem",
    placeItems: "start center",
  };

  const subCatVideo = items.map((video) => {
    return (
      <div
        key={video.id}
        className="position-relative small--thumbnails subcat--videos"
      >
        <Link to={`/video/${video.SubCategory}/${video.id}`}>
          <div className="overlay"></div>
          <ImPlay
            style={{
              position: "absolute",
              top: "38%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "red",
              width: "3rem",
              height: "3rem",
            }}
          />
          <img className="w-100" src={video.ImageUrl} alt="video thumbnails" />
        </Link>
        <p className="text-truncate m-0" style={{ maxWidth: "250px" }}>
          {video.Title}
        </p>
      </div>
    );
  });

  return (
    <>
      <div className="container-lg px-2 px-lg-0">
        <h5 className="my-2">
          <Link to="/video">ویدیو ها</Link>
        </h5>
        <div style={styles}>{subCatVideo}</div>
        <div className="mt-5 text-center">
          <small className="p-2 bg-secondary text-light rounded">
            پایان مطالب
          </small>
        </div>
      </div>
    </>
  );
}
