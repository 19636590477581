import React from "react";

export default function Login() {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center bg-body-secondary"
        style={{ height: "100vh" }}
        dir="ltr"
      >
        <div className="bg-white p-5">
          <h2 className="text-center fw-bold mb-4">LOGIN</h2>
          <form action="" method="post">
            <div
              className="form-group d-flex mb-3"
              style={{ height: "2.5rem", minWidth: "15rem" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-person-fill border rounded p-1"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
              <input
                type="text"
                name="username"
                id="username"
                className="form-control form-group-control-user"
                placeholder="username"
              />
            </div>
            <div
              className="form-group d-flex mb-3"
              style={{ height: "2.5rem", minWidth: "15rem" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="bi bi-shield-lock-fill border rounded p-1"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control form-group-control-user"
                placeholder="password"
              />
            </div>
            <div
              className="form-group d-flex mb-3"
              style={{ height: "2.5rem", minWidth: "15rem" }}
            >
              <button className="w-100 btn btn-primary">Log in</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
