import React from "react";
import { Link } from "react-router-dom";
import { footerLinks } from "../data";

export default function Footer() {
  const footerlinks = footerLinks.map((link) => {
    return (
      <div className="link-group mx-4 pb-5" key={link.id}>
        <Link className="text-decoration-none text-light" to={link.url}>
          <h6 className="mb-4">{link.name}</h6>
        </Link>
        {link.subcatLinks.map((subLink) => {
          return (
            <ul className="p-0" key={subLink.id}>
              <li>
                <Link
                  className="text-decoration-none text-light"
                  to={subLink.url}
                >
                  {subLink.text}
                </Link>
              </li>
            </ul>
          );
        })}
      </div>
    );
  });

  return (
    <div className="footer-container container-lg px-0 mt-5">
      <div className="py-4 justify-content-center d-flex flex-wrap">
        {footerlinks}
      </div>
    </div>
  );
}
