import React, { useEffect, useState } from "react";

export default function ContentForm() {
  const initialFormData = {
    Title: "",
    ShortDesc: "",
    YoutubeUrl: "",
    StatusId: false,
    PostDate: "",
    LanguageId: "",
    contentViews: "",
    userId: "",
    fulldesc: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  // handling file changes in form
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  // clearing form after submit
  const clearForm = () => {
    setFormData(initialFormData);
    document.getElementById("FileUrl").value = null;
    document.getElementById("ImageUrl").value = null;
    document.getElementById("selectCategory").value = "Select a category";
    document.getElementById("selectSubcategory").value = "Select a subcategory";
  };

  // // // getting data

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchSubcategories(selectedCategory);
  }, [selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://test001.ummatproduction.com/api/contentapi.php?option=1&type=5"
      );
      const categoriesData = await response.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubcategories = async (category) => {
    try {
      const response = await fetch(
        `https://test001.ummatproduction.com/api/contentapi.php?option=1&type=4&categoryid=${category}`
      );
      const subcategoriesData = await response.json();
      setSubcategories(subcategoriesData);
      setSelectedSubcategory("");
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const categorySelection = categories.map((category) => (
    <option key={category.id} value={category.id}>
      {category.CategoryD}
    </option>
  ));

  const subcategorySelect = subcategories.map((subcategory) => (
    <option key={subcategory.id} value={subcategory.id}>
      {subcategory.SubCategoryd}
    </option>
  ));

  // // // sending data

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a new FormData object
    const formData2 = new FormData();

    // Append the form data fields to the FormData object
    formData2.append("Title", formData.Title);
    formData2.append("ShortDesc", formData.ShortDesc);
    formData2.append("YoutubeUrl", formData.YoutubeUrl);
    formData2.append("StatusId", formData.StatusId);
    formData2.append("PostDate", formData.PostDate);
    formData2.append("LanguageId", formData.LanguageId);
    formData2.append("contentViews", formData.contentViews);
    formData2.append("userId", formData.userId);
    formData2.append("fulldesc", formData.fulldesc);

    formData2.append("category", selectedCategory);
    formData2.append("subcategory", selectedSubcategory);

    // Append the selected file to the FormData object
    formData2.append("FileUrl", selectedFile);
    formData2.append("ImageUrl", selectedImage);

    // Send form data to the server
    fetch("https://test001.ummatproduction.com/api/uploadapi.php", {
      method: "POST",
      body: formData2,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        // Show the response message temporarily
        setResponseMessage(data.message);
        setShowResponse(true);

        // Clear the form after successful submission
        clearForm();

        // Hide the response message after 5 seconds
        setTimeout(() => {
          setShowResponse(false);
          setResponseMessage("");
        }, 5000);
      })
      .catch((data) => {
        // Handle any errors that occurred during the API request
        setErrorMessage(data.error);
        setShowError(true);
        console.log(data);

        // Hide the response message after 5 seconds
        setTimeout(() => {
          setShowError(false);
          setErrorMessage("");
        }, 5000);
      });
  };

  return (
    <div>
      {/* <!-- Button trigger modal --> */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
        Upload content
      </button>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ marginLeft: "25%", marginTop: "5rem" }}
        >
          <div className="modal-content" style={{ width: "50rem" }}>
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Upload content
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group modal-body row g-3">
                <div className="col-md-6">
                  <label htmlFor="category" className="form-label">
                    Category
                  </label>
                  <select
                    id="selectCategory"
                    required
                    name="category"
                    value={formData.category}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="form-select"
                  >
                    <option>Select a category</option>
                    {categorySelection}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="subcategory" className="form-label">
                    Subcategory
                  </label>
                  <select
                    id="selectSubcategory"
                    required
                    name="subcategory"
                    value={formData.subcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    className="form-select"
                  >
                    <option>Select a subcategory</option>
                    {subcategorySelect}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Title"
                    value={formData.Title}
                    onChange={handleChange}
                    name="Title"
                    required
                  />
                </div>
                <div
                  className={`col-6 ${selectedCategory === "1" && "d-none"}
                  ${selectedCategory === "2" && "d-none"}`}
                >
                  <label htmlFor="ShortDesc" className="form-label">
                    Short Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ShortDesc"
                    value={formData.ShortDesc}
                    onChange={handleChange}
                    name="ShortDesc"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="ImageUrl" className="form-label">
                    Image
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="ImageUrl"
                    onChange={handleImageChange}
                    name="ImageUrl"
                    accept="image/*"
                  />
                </div>
                <div
                  className={`col-6 ${selectedCategory === "1" && "d-none"}
                  ${selectedCategory === "2" && "d-none"}`}
                >
                  <label htmlFor="FileUrl" className="form-label">
                    File
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="FileUrl"
                    onChange={handleFileChange}
                    name="FileUrl"
                    accept=".pdf,.doc,.docx"
                  />
                </div>
                <div
                  className={`col-6 ${selectedCategory === "3" && "d-none"}
                  ${selectedCategory === "4" && "d-none"}
                  ${selectedCategory === "5" && "d-none"}
                  ${selectedCategory === "6" && "d-none"}
                  ${selectedCategory === "7" && "d-none"}`}
                >
                  <label htmlFor="YoutubeUrl" className="form-label">
                    {selectedCategory === "1" ? "Youtube" : "Audio"} Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="YoutubeUrl"
                    value={formData.YoutubeUrl}
                    onChange={handleChange}
                    name="YoutubeUrl"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="youtubeUrl" className="form-label">
                    Language
                  </label>
                  <select
                    name="LanguageId"
                    value={formData.LanguageId}
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option>Select a language</option>
                    <option value="1">Dari</option>
                    <option value="2">Pashto</option>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="PostDate" className="form-label">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="PostDate"
                    value={formData.PostDate}
                    onChange={handleChange}
                    name="PostDate"
                  />
                </div>
                <div className="col-4 form-check form-switch px-2">
                  <label className="form-check-label" htmlFor="StatusId">
                    Publish
                  </label>
                  <br />
                  <input
                    className="form-check-input"
                    style={{
                      width: "3em",
                      height: "1.7em",
                      transform: "translate(2.5em, 0.5em)",
                    }}
                    type="checkbox"
                    role="switch"
                    id="StatusId"
                    checked={formData.StatusId}
                    onChange={handleChange}
                    name="StatusId"
                  />
                </div>
                <div
                  className={`col-12 ${selectedCategory !== "4" && "d-none"}`}
                >
                  <label htmlFor="fulldesc" className="form-label">
                    Long Description
                  </label>
                  <textarea
                    className="form-control"
                    id="fulldesc"
                    value={formData.fulldesc}
                    onChange={handleChange}
                    name="fulldesc"
                    rows="4"
                  />
                </div>
              </div>
              {showResponse && (
                <div className="alert alert-success col-12">
                  {responseMessage}
                </div>
              )}
              {showError && (
                <div className="alert alert-danger col-12">
                  Something went wrong. Please check your connection or contact
                  administrator. {errorMessage}
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => clearForm()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Upload
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
